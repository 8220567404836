import styled from "styled-components";

import { SettingsButtonStyledProps as Props } from "./SettingsButton.types";

const SettingsButtonStyled = styled.div<Props>`
  cursor: pointer;

  .SettingsButton {
  }
`;

export default SettingsButtonStyled;
