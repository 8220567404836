import React, { useState } from "react";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";

import Styles from "./SettingsButton.styles";
import { SettingsButtonProps as Props } from "./SettingsButton.types";
import SettingsDrawer from "components/global/SettingsDrawer/SettingsDrawer";

import SettingsSVG from "../../../../../public/assets/images/settings.svg";
import CONSTANTS from "config/constants";

const { Item } = TabsMenuUI;
const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const SettingsButton: React.FC<Props> = props => {
  const { className } = props;
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => setShowDrawer(prev => !prev);

  if (!WITH_PURCHASE) return null;

  return (
    <Styles className={`SettingsButton ${className}`}>
      <Item icon={SettingsSVG} onClick={toggleDrawer} />
      <SettingsDrawer opened={showDrawer} onClose={toggleDrawer} />
    </Styles>
  );
};

SettingsButton.defaultProps = {
  className: ""
};

export default SettingsButton;
