import React, { useState } from "react";
import { ButtonGroup, Drawer } from "artisn-ui-react";

import useI18n from "hooks/useI18n";
import Styles from "./SettingsDrawer.styles";
import TitlePocket from "./TitlePocket/TitlePocket";
import InfoCard from "components/global/InfoCard/InfoCard";
import useStores from "contexts/stores/stores.context.hooks";
import CONSTANTS, { DELIVERY, PICK_UP } from "config/constants";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { SettingsDrawerProps as Props } from "./SettingsDrawer.types";

import TimesSVG from "../../../../public/assets/images/times.svg";
import CatalogueSVG from "../../../../public/assets/images/catalogue.svg";

const { Button } = ButtonGroup;
const { WITH_MULTIPLE_PURCHASE_METHODS } = CONSTANTS.FEATURE_FLAGS;

const SettingsDrawer: React.FC<Props> = props => {
  const t = useI18n();
  const { opened, onClose } = props;
  const { selectedCatalogue, setSelectedCatalogueId } = useCatalogues();
  const [catalogueId, setCatalogueId] = useState(selectedCatalogue.catalogueId);
  const { selectedStore, setSelectedStore } = useStores();
  const [store, setStore] = useState(selectedStore);

  const submitHandler = () => {
    setSelectedCatalogueId(catalogueId);
    setSelectedStore(store);
    onClose();
  };

  const closeHandler = () => {
    setCatalogueId(selectedCatalogue.catalogueId);
    setStore(selectedStore);
    onClose();
  };

  return (
    <Styles className="SettingsDrawer">
      <Drawer
        className="SettingsDrawer__drawer"
        opened={opened}
        onClose={closeHandler}
      >
        <div className="SettingsDrawer__drawer__header">
          <h3 className="SettingsDrawer__drawer__header__title">
            {t.common.preferences}
          </h3>
          <div
            className="SettingsDrawer__drawer__header__cross"
            onClick={closeHandler}
          >
            <TimesSVG />
          </div>
        </div>
        {WITH_MULTIPLE_PURCHASE_METHODS ? (
          <>
            <p className="SettingsDrawer__drawer__buttonGroupText">
              {t.common.workflows}
            </p>
            <ButtonGroup
              className="SettingsDrawer__buttonGroup"
              onChange={e => setCatalogueId(e.target.value)}
              active={catalogueId}
            >
              <Button
                className="SettingsDrawer__buttonGroup__button"
                value={DELIVERY.catalogueId}
                id={DELIVERY.catalogueId}
              >
                {t.catalogues.delivery}
              </Button>
              <Button
                className="SettingsDrawer__buttonGroup__button"
                value={PICK_UP.catalogueId}
                id={PICK_UP.catalogueId}
              >
                {t.catalogues.pickup}
              </Button>
            </ButtonGroup>
          </>
        ) : null}
        <div className="SettingsDrawer__separator" />
        <TitlePocket />
        <div className="SettingsDrawer__separator" />
        <InfoCard
          className="SettingsDrawer__info-card"
          Icon={CatalogueSVG}
          title={t.common.catalogue}
          value={selectedCatalogue.name}
        />
        {WITH_MULTIPLE_PURCHASE_METHODS ? (
          <button
            className="SettingsDrawer__drawer__button"
            onClick={submitHandler}
          >
            <p className="SettingsDrawer__drawer__button__text">
              {t.common.apply}
            </p>
          </button>
        ) : null}
      </Drawer>
    </Styles>
  );
};

SettingsDrawer.defaultProps = {};

export default SettingsDrawer;
