import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const en = {
  title: "El hornero",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "¿Quieres pedir comida a domicilio? Elige entre pizza en horno de leña, pastas, sandwiches, hamburguesas y carnes. El Hornero, mucho más que pizza. Pide ahora."
  },
  productsSearch: {
    metaTitle: "Descubre"
  },
  loyalty: {
    metaTitle: "Lealtad"
  },
  coupons: {
    metaTitle: "Cupones",
    detailTitle: "Cupones"
  },
  profile: {
    metaTitle: "Usuario"
  },
  navigation: {
    shopping: "Menú",
    discover: "Descubre",
    loyalty: "Lealtad",
    coupons: "Cupones",
    profile: "Usuario"
  },
  catalogues: {
    delivery: "Entrega",
    pickup: "Retiro",
    other: "Otro"
  },
  common: {
    preferences: "Preferencias",
    workflows: "Modalidad de compra",
    apply: "Aplicar",
    buyingIn: "Comprando en",
    catalogue: "Catálogo"
  }
};

export default en;
