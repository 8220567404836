import styled from "styled-components";

import { NavbarStyledProps as Props } from "./Navbar.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS, FEATURE_FLAGS } = CONSTANTS;
const { tablet, desktop } = BREAKPOINTS;
const { WITH_SELECT_ADDRESS_DROPDOWN } = FEATURE_FLAGS;

const NavbarStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: var(--palette-white);
  height: var(--sizes-navbar-height);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  align-content: center;
  grid-template-areas: ". content .";
  box-shadow: 0 0.2rem 0.4rem 0 var(--palette-gray-s0-l0-a05);

  @media (max-width: ${tablet}px) {
    align-content: start;
    height: var(--sizes-navbar-height-mobile);
    padding-top: env(safe-area-inset-top);
  }

  &&& .Navbar {
    &__content {
      grid-area: content;

      @media (max-width: ${tablet}px) {
        grid-column: 1 / -1;
      }
    }

    &__alert {
      display: flex;
      justify-content: space-between;

      &__icon {
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.4rem;
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__logo {
      svg {
        @media (max-width: ${desktop - 1}px) {
          width: 12rem;
        }

        @media (max-width: ${tablet}px) {
          width: 10rem;
        }
      }
    }

    &__dropdown {
      margin-left: 2.4rem;
      margin-right: 1.6rem;
      flex-shrink: 0.03;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__top {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      align-items: center;

      &--brand {
        @media (max-width: ${tablet}px) {
          padding: 1.2rem 1.6rem;
        }
        & .not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &--responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
    }

    &__back {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
    }

    &__bottom {
      display: none;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0.8rem 0;
      border-top: 0.1rem solid var(--palette-lightgrey-s5-l90);

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        background-color: var(--palette-gray-s0-l97);
        border-top: none;
        padding: 0;
      }

      &__dropdown {
        display: none;

        @media (max-width: ${tablet}px) {
          width: 100%;
          display: block;
          border-radius: 0;
          height: unset;
        }
      }

      &__button {
        border-radius: 2rem;
        font-size: 1.6rem;
        font-weight: 600;
        margin-left: 0.8rem;
      }
    }

    &__choose-workflow-mobile {
      margin-left: 0.8rem;
    }

    &__select-address-dropdown {
      width: 100%;
      margin-left: ${WITH_SELECT_ADDRESS_DROPDOWN ? "2.4rem" : "auto"};
      margin-right: 2.4rem;
    }

    &__search-bar {
      display: flex;
      width: 100%;
      margin-right: 2rem;

      @media (max-width: ${tablet}px) {
        width: min-content;
        margin-left: auto;
        margin-right: 0;
        background-color: var(--palette-lightgrey-s5-l90);
        border: unset;
        padding: 0.8rem 1.2rem;
        border-radius: 2rem;
      }

      &--center {
        margin: 0;
        max-width: 60rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        overflow: hidden;

        .SearchBar__input {
          max-width: 19.2rem;
        }

        @media (max-width: 360px) {
          padding: 0;
          max-width: 22rem;

          .SearchBar__input {
            max-width: 15.2rem;
          }
        }
      }

      &--bottom {
        margin: 0;
        max-width: none;
      }

      &--mobile {
        display: none;

        @media (max-width: ${tablet}px) {
          display: flex;
        }
      }
    }

    &__btn {
      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      &--loyalty {
      }

      &--coupons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11.8rem;
        height: 4.8rem;
        border-radius: 10rem;
        background-color: var(--palette-secondary-s35-l95);
        color: var(--palette-primary);
        font-size: 1.6rem;
        font-weight: 800;
        cursor: pointer;
        transition: transform 0.4s ease-in-out 0s, background-color 0.5s ease 0s;

        &:hover {
          background-color: var(--palette-primary);
          transform: scale(1.04);

          .TabsMenuItem {
            svg {
              path {
                fill: var(--palette-white);
              }
            }
          }

          .TabsMenuItem__title {
            color: var(--palette-white);
          }
        }

        .TabsMenuItem {
          flex-direction: row;
          height: 100%;
          padding: 0 1.6rem;

          @media (max-width: ${desktop - 1}px) {
            aspect-ratio: 1 / 1;
          }

          &__title {
            padding: 0;
            font-size: 1.6rem;
            font-weight: 800;
            color: var(--palette-primary);

            @media (max-width: ${desktop - 1}px) {
              display: none;
            }
          }

          svg {
            width: 1.4rem;
            height: 1rem;
            margin-right: 0.4rem;

            @media (max-width: ${desktop - 1}px) {
              margin: 0;
            }

            path {
              fill: var(--palette-primary);
            }
          }
        }

        &.Button:hover {
          background-color: var(--palette-secondary-s35-l95);
        }

        > svg {
          margin-right: 0.4rem;
        }
      }

      &--profile {
        cursor: pointer;

        path {
          transition: all 0.5s ease 0s;
        }

        &:hover {
          path {
            fill: var(--palette-gray-s0-l50);
          }
        }

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &--cart {
        margin-left: auto;

        @media (max-width: ${tablet}px) {
          margin-left: 0;
        }
      }

      &--order-now {
        height: 4.8rem;
        border-radius: 12rem;
        font-size: 1.6rem;
        font-weight: 800;
        white-space: nowrap;

        @media (max-width: ${tablet}px) {
          flex: 1;
        }
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &__order-now {
      height: 6rem;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 1.6rem;
    }

    &__btn--loyalty,
    &__btn--coupons,
    &__btn--profile,
    &__choose-workflow-desktop,
    &__select-address-dropdown-desktop {
      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }
`;

export default NavbarStyled;
