import React, { useState } from "react";
import { Pocket } from "artisn-ui-react";

import useI18n from "hooks/useI18n";
import Styles from "./TitlePocket.styles";
import InfoCard from "components/global/InfoCard/InfoCard";
import useStores from "contexts/stores/stores.context.hooks";
import { TitlePocketProps as Props } from "./TitlePocket.types";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";

import LocationSVG from "../../../../../public/assets/images/location.svg";

const TitlePocket: React.FC<Props> = props => {
  const t = useI18n();
  const { selectedStore } = useStores();
  const { storeName = "Sin seleccionar" } = selectedStore ?? {};
  const [opened, setOpened] = useState(false);

  const onPocketClick = () => {
    setOpened(prev => !prev);
  };

  return (
    <Styles className="TitlePocket">
      <Pocket
        title={
          <InfoCard
            Icon={LocationSVG}
            title={t.common.buyingIn}
            value={storeName}
          />
        }
        className="TitlePocket__pocket"
        onChange={onPocketClick}
      />
      <ChooseStoreModal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      />
    </Styles>
  );
};

TitlePocket.defaultProps = {};

export default TitlePocket;
