import React from "react";
import { useRouter } from "next/router";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";

import Styles from "./LoyaltyTabsMenuItem.styles";
import { LoyaltyTabsMenuItemProps as Props } from "./LoyaltyTabsMenuItem.types";

import StarSVG from "../../../../../public/assets/images/TabsMenu/star.svg";

const { Item } = TabsMenuUI;

const LoyaltyTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();

  const onClickHandler = async () => {
    await push("/loyalty");
  };

  return (
    <Styles className={`LoyaltyTabsMenuItem ${className}`} active={active}>
      <Item
        icon={StarSVG}
        title={hideTitle ? undefined : "Lealtad"}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

LoyaltyTabsMenuItem.defaultProps = {
  className: ""
};

export default LoyaltyTabsMenuItem;
