import React from "react";
import { Capacitor } from "@capacitor/core";

import Styles from "./ToastNotification.styles";
import { ToastNotificationProps as Props } from "./ToastNotification.types";
import useStores from "contexts/stores/stores.context.hooks";
import useWindowSize from "hooks/useWindowSize";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ToastNotification: React.FC<Props> = props => {
  const { noCoverage, selectedStore } = useStores();
  const { className, icon, message } = props;
  const { isOpen } = selectedStore ?? {};
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;
  const { width } = useWindowSize();
  const isReponsive = width <= tablet;
  const showStoreNotification =
    !isOpen &&
    !noCoverage &&
    (isReponsive || isMobile) &&
    typeof isOpen !== "undefined";

  if (!showStoreNotification) return null;

  return (
    <Styles className={`ToastNotification ${className}`}>
      {icon ? icon : null}
      <div className="ToastNotification__message">{message}</div>
    </Styles>
  );
};

ToastNotification.defaultProps = {
  className: ""
};

export default ToastNotification;
