import styled from "styled-components";

import { ToastNotificationStyledProps as Props } from "./ToastNotification.types";

const ToastNotificationStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  padding: 0.4rem 1.6rem;
  background-color: var(--palette-red-s69-l63);
  color: var(--palette-white);
  font-weight: 500;
  height: 2.6rem;

  .ToastNotification {
    svg {
      margin-right: 0.6rem;
    }
  }
`;

export default ToastNotificationStyled;
