import React, { useEffect, useState } from "react";
import { Button, Dropdown, Backdrop } from "artisn-ui-react";

import Styles, { DropdownOverlayStyled } from "./SelectAddressDropdown.styles";
import { SelectAddressDropdownProps as Props } from "./SelectAddressDropdown.types";
import ChooseWorkflowSwitch from "components/global/ChooseWorkflowSwitch/ChooseWorkflowSwitch";
import Divider from "components/global/Divider/Divider";
import useStores from "contexts/stores/stores.context.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { getCurrentPosition } from "utils/geo.utils";
import useGeo from "contexts/geo/geo.hooks";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";
import { createErrorNotification } from "utils/notifications.utils";
import { mobileBackdropConfig } from "./SelectAddressDropdown.helpers";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";

import DeliverySVG from "../../../../public/assets/images/delivery.svg";
import PickupSVG from "../../../../public/assets/images/pickup.svg";
import GeoSVG from "../../../../public/assets/images/geo.svg";
import MapSVG from "../../../../public/assets/images/map.svg";
import StoreSVG from "../../../../public/assets/images/store.svg";
import ArrowDropdownSVG from "../../../../public/assets/images/arrow-dropdown.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";

const { FEATURE_FLAGS, BREAKPOINTS } = CONSTANTS;
const { WITH_PURCHASE, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { tablet } = BREAKPOINTS;

const SelectAddressDropdown: React.FC<Props> = props => {
  const { className } = props;
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const [openChooseAddress, setOpenChooseAddress] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const { refetch: refetchNearbyStores } = useFetchNearbyStores();
  const { selectedCatalogue } = useCatalogues();
  const { selectedStore, showStoreNotification } = useStores();
  const { setShowStoreNotification } = useStores();
  const { storeName } = selectedStore ?? {};
  const { name: selectedCatalogueName } = selectedCatalogue;
  const isDelivery = selectedCatalogueName === "Delivery";
  const { data: predictions, error } =
    useFetchGoogleAddressFromCoords(selectedCoordinates);
  const { message } = error ?? {};
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;
  const { selectedAddressDropdown, setSelectedAddressDropdown } =
    useShippingAddress();
  const { showBackdropAddressDropdown, setShowBackdropAddressDropdown } =
    useShippingAddress();
  const { width } = useWindowSize();
  const isMobile = width <= tablet;
  const useCurrentLocationHandler = () => {
    setSelectedAddressDropdown(false);
    if (isDelivery) {
      getCurrentLocation();
      setShowBackdropAddressDropdown(false);
      return;
    }

    refetchNearbyStores();
    setShowBackdropAddressDropdown(false);
  };

  const chooseLocationHandler = () => {
    setSelectedAddressDropdown(false);
    if (isDelivery) {
      setOpenChooseAddress(true);
      setShowBackdropAddressDropdown(false);
      return;
    }

    setOpenChooseStore(true);
    setShowBackdropAddressDropdown(false);
  };

  const getCurrentLocation = async () => {
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
      },
      error => {
        setGeoCodeAddress(error.message);
      }
    );
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  useEffect(() => {
    if (message) createErrorNotification(message);
  }, [message]);

  useEffect(() => {
    if (showStoreNotification) {
      setSelectedAddressDropdown(false);
      setOpenChooseStore(false);
      setOpenChooseAddress(false);
    }
  }, [setSelectedAddressDropdown, showStoreNotification]);

  useEffect(() => {
    if (geoCodeAddress === "Not implemented on web.") {
      setGeoCodeAddress(undefined);
    }
  }, [geoCodeAddress]);

  const buttonNode = (
    <Button
      className="SelectAddressDropdown__button"
      onClick={() => {
        if (!isMobile) {
          setSelectedAddressDropdown(prev => !prev);
        }
        setShowStoreNotification(false);
      }}
    >
      {isDelivery ? (
        <DeliverySVG
          className="SelectAddressDropdown__icon"
          viewBox="0 0 16 16"
        />
      ) : (
        <PickupSVG
          className="SelectAddressDropdown__icon"
          viewBox="0 0 16 16"
        />
      )}
      <div className="SelectAddressDropdown__button-text-container">
        <p className="SelectAddressDropdown__button-text">
          {isDelivery ? "Entregar ahora en" : "Recoger ahora en"}
        </p>
        <span className="SelectAddressDropdown__button__divider">・</span>
        <p className="SelectAddressDropdown__button-text">
          {isDelivery
            ? geoCodeAddress || "Selecciona una dirección"
            : storeName || "Selecciona una tienda"}
        </p>
      </div>
      <ArrowDropdownSVG />
    </Button>
  );

  const overlayNode = (
    <DropdownOverlayStyled className="DropdownOverlay">
      <CloseSVG
        className="DropdownOverlay__close-icon"
        onClick={() => setShowBackdropAddressDropdown(false)}
      />
      <ChooseWorkflowSwitch className="DropdownOverlay__choose-workflow-desktop" />
      <Button
        className="DropdownOverlay__option DropdownOverlay__select-address"
        type="LINK"
        onClick={useCurrentLocationHandler}
      >
        <GeoSVG viewBox="0 0 16 16" />
        {isDelivery
          ? "Utilizar mi ubicación actual"
          : "Seleccionar tienda más cercana"}
      </Button>
      <Divider className="DropdownOverlay__divider" />
      <Button
        className="DropdownOverlay__option"
        type="LINK"
        onClick={chooseLocationHandler}
      >
        {isDelivery ? (
          <>
            <MapSVG viewBox="0 0 24 25" />
            Selecciona una dirección
          </>
        ) : (
          <>
            <StoreSVG viewBox="0 0 20 17" />
            Selecciona una tienda
          </>
        )}
      </Button>
    </DropdownOverlayStyled>
  );

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`SelectAddressDropdown ${className}`}
      talkShop={talkShop}
    >
      <Dropdown
        className="SelectAddressDropdown__dropdown"
        overlay={overlayNode}
        target={["click"]}
        position={"left"}
        isFixed
        showDropdown={selectedAddressDropdown}
        backdropConfig={{
          onClick: () => setSelectedAddressDropdown(false)
        }}
      >
        {buttonNode}
      </Dropdown>
      <div
        className="SelectAddressDropdown__button-container"
        onClick={() => setShowBackdropAddressDropdown(true)}
      >
        {buttonNode}
      </div>
      {showBackdropAddressDropdown ? (
        <Backdrop
          {...mobileBackdropConfig}
          opened={showBackdropAddressDropdown}
          onClick={() => setShowBackdropAddressDropdown(false)}
        >
          {overlayNode}
        </Backdrop>
      ) : null}
      {openChooseAddress ? (
        <ShippingAddressModal
          opened={openChooseAddress}
          onClose={() => setOpenChooseAddress(false)}
          locationOnly
        />
      ) : null}
      <ChooseStoreModal
        opened={openChooseStore}
        onClose={() => setOpenChooseStore(false)}
      />
    </Styles>
  );
};

SelectAddressDropdown.defaultProps = {
  className: ""
};

export default SelectAddressDropdown;
