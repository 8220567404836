import styled from "styled-components";

import { LoyaltyTabsMenuItemStyledProps as Props } from "./LoyaltyTabsMenuItem.types";

const LoyaltyTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props => (props.active ? "var(--palette-primary)" : "")};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  }

  .LoyaltyTabsMenuItem {
  }
`;

export default LoyaltyTabsMenuItemStyled;
