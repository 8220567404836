import styled from "styled-components";
import { ButtonGroup, ButtonGroupProps as Props } from "artisn-ui-react";

const ChooseWorkflowSwitchStyled = styled(ButtonGroup)<Props>`
  display: flex;
  height: 4.8rem;
  padding: 0.4rem 0.8rem;
  border-radius: 12rem;
  background-color: var(--palette-gray-s0-l97);
  flex-wrap: nowrap;

  .ChooseWorkflowSwitch {
    &__button {
      flex-grow: 1;
      width: 8.5rem;
      text-align: center;
      border-radius: 12rem;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: none;
        color: var(--palette-black-s0-l20);
      }

      &__text {
        margin-left: 0.4rem;
        font-size: 1.4rem;
        font-weight: 800;
        white-space: nowrap;
        color: var(--palette-black-s0-l20);
      }

      svg {
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;

        path {
          fill: var(--palette-black-s0-l20);
        }
      }

      &.Button--active {
        background-color: var(--palette-white);
        border: none;
        box-shadow: 0 0.4rem 0.4rem var(--palette-gray-s0-l20-a04),
          0 0.4rem 1.6rem var(--palette-gray-s0-l20-a08);

        .ChooseWorkflowSwitch__button__text {
          color: var(--palette-primary);
        }

        svg {
          path {
            fill: var(--palette-primary);
          }
        }
      }
    }
  }
`;

export default ChooseWorkflowSwitchStyled;
