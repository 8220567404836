import styled from "styled-components";

import { CartButtonStyledProps as Props } from "./CartButton.types";
import CONSTANTS from "config/constants";

const { WITH_WISHLIST } = CONSTANTS.FEATURE_FLAGS;
const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const CartButtonStyled = styled.div<Props>`
  cursor: pointer;
  height: 4.8rem;

  @media (max-width: ${mobile}px) {
    height: auto;
  }

  && .CartButton {
    &__button {
      height: 100%;
      border-radius: 12rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      background-color: var(--palette-white);

      &:hover {
        &:not([disabled]) {
          @media (max-width: ${mobile}px) {
            background-color: unset;
            border: unset;
          }

          .CartButton__button__text {
            color: var(--palette-white);
          }

          svg {
            path {
              fill: var(--palette-white);

              @media (max-width: ${mobile}px) {
                fill: var(--palette-black-s0-l20);
              }
            }
          }
        }
      }

      @media (max-width: ${desktop}px) {
        width: 4.8rem;
        height: 4.8rem;
        background-color: ${props =>
          props.isEmptyCart
            ? `var(--palette-secondary-s35-l95)`
            : `var(--palette-primary)`};
        border: none;
      }

      @media (max-width: ${tablet}px) {
        background-color: var(--palette-primary);
        margin-left: 0.8rem;
        height: 100%;
        width: auto;
      }

      @media (max-width: ${mobile}px) {
        padding: 0.8rem;
        margin-left: 0.4rem;
        width: 4rem;
        height: 4rem;
        background-color: transparent;
        border: none;
      }

      svg path {
        fill: var(--palette-primary);

        @media (max-width: ${desktop}px) {
          fill: ${props =>
            props.isEmptyCart
              ? `var(--palette-primary)`
              : `var(--palette-white)`};
        }

        @media (max-width: ${tablet}px) {
          fill: var(--palette-white);
        }

        @media (max-width: ${mobile}px) {
          fill: var(--palette-black-s0-l20);
        }
      }

      &__text {
        font-size: 1.6rem;
        white-space: nowrap;
        font-weight: 800;
        color: var(--palette-primary);

        @media (max-width: ${desktop}px) {
          display: none;
        }

        @media (max-width: ${tablet}px) {
          display: block;
          color: var(--palette-white);
        }

        @media (max-width: ${mobile}px) {
          display: none;
        }
      }
    }

    &__badge {
      .Badge__value {
        width: 1.4rem;
        min-width: 1.4rem;
        height: 1.4rem;
        top: 0.6rem;
        right: 0.2rem;
        background-color: var(--palette-black-s0-l20);
        border: 0.1rem solid var(--palette-secondary-s35-l95);
        padding: 0;

        @media (max-width: ${mobile}px) {
          background-color: var(--palette-primary);
        }
      }
    }

    &--heart {
      display: none;

      path {
        fill: var(--palette-primary);
      }

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST && props.isSignedIn
            ? "block"
            : "none"};
      }
    }

    &--cart {
      display: flex;
    }
  }
`;

export default CartButtonStyled;
