import React from "react";
import { useRouter } from "next/router";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";

import Styles from "./CouponsTabsMenuItem.styles";
import { CouponsTabsMenuItemProps as Props } from "./CouponsTabsMenuItem.types";

import CouponSVG from "../../../../../public/assets/images/TabsMenu/coupons.svg";

const { Item } = TabsMenuUI;

const CouponsTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();

  const onClickHandler = async () => {
    await push("/coupons");
  };

  const icon = () => <CouponSVG viewBox="0 0 28 20" />;

  return (
    <Styles className={`CouponsTabsMenuItem ${className}`} active={active}>
      <Item
        icon={icon}
        title={hideTitle ? undefined : "Cupones"}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

CouponsTabsMenuItem.defaultProps = {
  className: ""
};

export default CouponsTabsMenuItem;
