import styled from "styled-components";

import { SettingsDrawerStyledProps as Props } from "./SettingsDrawer.types";

const SettingsDrawerStyled = styled.div<Props>`
  cursor: auto;

  .SettingsDrawer {
  }
`;

export default SettingsDrawerStyled;
