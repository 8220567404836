import styled from "styled-components";

import { TitlePocketStyledProps as Props } from "./TitlePocket.types";

const TitlePocketStyled = styled.div<Props>`
  .TitlePocket {
    &__pocket {
      padding: 1.6rem 0;
    }
  }
`;

export default TitlePocketStyled;
