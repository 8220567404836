import React, { useMemo } from "react";
import { useRouter } from "next/router";

import Logo from "../Logo/Logo";
import Styles from "./Navbar.styles";
import GoBack from "../GoBack/GoBack";
import CONSTANTS from "config/constants";
import { NavbarProps as Props } from "./Navbar.types";
import CartButton from "../navigation/CartButton/CartButton";
import SearchBarProducts from "../SearchBarProducts/SearchBarProducts";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import LoyaltyTabsMenuItem from "../navigation/LoyaltyTabsMenuItem/LoyaltyTabsMenuItem";
import CouponsTabsMenuItem from "../navigation/CouponsTabsMenuItem/CouponsTabsMenuItem";
import SelectAddressDropdown from "components/global/SelectAddressDropdown/SelectAddressDropdown";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import SettingsButton from "../navigation/SettingsButton/SettingsButton";
import useWindowSize from "hooks/useWindowSize";
import Button from "../Button/Button";
import useProducts from "contexts/products/products.context.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import { nextOpeningHourHelper } from "../StorePreview/StorePreview.helpers";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import ToastNotification from "../ToastNotification/ToastNotification";

import SearchSVG from "../../../../public/assets/images/search.svg";
import StoreSVG from "../../../../public/assets/images/store-white.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_LOYALTY, WITH_STORE_COUPONS } = FEATURE_FLAGS;
const { WITH_SELECT_ADDRESS_DROPDOWN, WITH_TALK_SHOP } = FEATURE_FLAGS;
const { WITH_PURCHASE } = FEATURE_FLAGS;
const { tablet } = CONSTANTS.BREAKPOINTS;

const Navbar: React.FC<Props> = props => {
  const { className, showBrand = false, title, icon, product, to } = props;
  const { showBottomContent = true, showCart = true, shareIcon } = props;
  const { push, pathname } = useRouter();
  const notHome = !showBrand ? "not-home" : "";
  const isShowCart = !showCart ? "Navbar__btn--hidden" : null;
  const { width: windowWidth } = useWindowSize();
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && talkShopProvider && talkShopIdentifier;
  const isResponsive = windowWidth <= tablet;
  const { setOpenSearchBarDrawer } = useProducts();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const { schedules } = selectedStore ?? {};

  const nextOpeningHour = useMemo(() => {
    if (!schedules) return;
    return nextOpeningHourHelper(schedules, +selectedCatalogueId);
  }, [selectedCatalogueId, schedules]);

  const renderCartButton = () => {
    if (!WITH_PURCHASE) return null;
    return (
      <CartButton
        className={`Navbar__btn Navbar__btn--cart ${isShowCart}`}
        product={product}
      />
    );
  };

  const iconNode = (
    <div className="Navbar__icon">
      {renderCartButton()}
      {shareIcon}
    </div>
  );

  return (
    <Styles
      className={`Navbar ${className}`}
      showBottomContent={showBottomContent}
      talkShop={!!talkShop}
      id="navbar"
    >
      <main className="Navbar__content">
        <ToastNotification
          className="Navbar__alert"
          icon={
            <div className="Navbar__alert__icon">
              <StoreSVG /> Tienda cerrada
            </div>
          }
          message={`Abre a las ${nextOpeningHour}`}
        />
        <div className={`Navbar__top Navbar__top--responsive ${notHome}`}>
          <GoBack to={to} className="Navbar__back" />
          {title || title === "" ? (
            <p className="Navbar__title">{title}</p>
          ) : (
            <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--center" />
          )}
          {icon ?? iconNode}
        </div>
        <div className={`Navbar__top Navbar__top--brand ${notHome}`}>
          {talkShop ? (
            <Logo className="Navbar__logo" onClick={() => {}} />
          ) : (
            <Logo className="Navbar__logo" />
          )}
          {WITH_SELECT_ADDRESS_DROPDOWN && !isResponsive ? (
            <SelectAddressDropdown className="Navbar__dropdown" />
          ) : null}
          {!isResponsive ? (
            <SearchBarProducts className="Navbar__search-bar Navbar__search-bar--top" />
          ) : null}
          {isResponsive ? (
            <Button
              className="Navbar__search-bar Navbar__search-bar--top Navbar__search-bar--mobile"
              onClick={() => setOpenSearchBarDrawer(prev => !prev)}
            >
              <SearchSVG />
            </Button>
          ) : null}
          {WITH_LOYALTY ? (
            <LoyaltyTabsMenuItem
              hideTitle
              className="Navbar__btn Navbar__btn--loyalty"
              active={false}
            />
          ) : null}
          {WITH_STORE_COUPONS ? (
            <CouponsTabsMenuItem
              className="Navbar__btn Navbar__btn--coupons"
              active={false}
            />
          ) : null}
          <CartButton
            className="Navbar__btn Navbar__btn--cart"
            product={product}
          />
          {pathname === "/" && windowWidth > tablet ? (
            <Button
              className="Navbar__btn Navbar__btn--order-now"
              onClick={() => push("/categories")}
            >
              Menu
            </Button>
          ) : null}
          {windowWidth > tablet ? (
            <UserTabsMenuItem
              hideTitle
              className="Navbar__btn Navbar__btn--profile"
              active={false}
            />
          ) : null}
          {!WITH_SELECT_ADDRESS_DROPDOWN ? (
            <SettingsButton className="Navbar__btn Navbar__btn--settings" />
          ) : null}
        </div>
        {isResponsive ? (
          <div className="Navbar__bottom">
            {WITH_SELECT_ADDRESS_DROPDOWN && pathname !== "/" ? (
              <SelectAddressDropdown className="Navbar__bottom__dropdown" />
            ) : (
              <div className="Navbar__order-now">
                <Button
                  className="Navbar__btn Navbar__btn--order-now"
                  onClick={() => push("/categories")}
                >
                  Ordenar ahora
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </main>
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
